const credentials = {
    username: process.env.REACT_APP_BASIC_AUTH_USERNAME,
    password: process.env.REACT_APP_BASIC_AUTH_PASSWORD,
}

async function doGet(url, config = {}) {
    var config = {
        "method": "GET",
        ...config,
        "headers": {
            "Accept": "application/json",
            ...config && config.headers,
            "Authorization": "Basic " + window.btoa(credentials.username + ":" + credentials.password),
        }
    };

    try {
        const response = await window.fetch(url, config);
        return response.json();
    } catch (response) {
        var message = await response.text();
        return Promise.reject(new Error(message));
    }
}

async function doPost(url, data = {}, config = {}) {
    var headers = {
        "Accept": "application/json",
        "Content-Type": "application/json",
    }

    var config = {
        "method": "POST",
        ...config,
        "headers": {
            ...headers,
            ...config && config.headers,
        },
        "body": data,
        "redirect": 'follow'
    };

    try {
        const response = await window.fetch(url, config);
        return response.json();
    } catch (response) {
        var message = await response.text();
        return Promise.reject(new Error(message));
    }

}

export default { doGet, doPost }
